<template>
  <div class="bso-escribe">
    <v-card class="mb-2">
      <v-card-text>
        <h1 class="black--text text-center pb-1">BSO ESCRIBE - Textos</h1>
        <p class="text-body-2 black--text">Porque no sólo nos gusta la radio. Porque los pensamientos no sólo se charlan o se escuchan sino que también se escriben. Porque, tal vez, algunos textos se pueden volver imágenes cinematográficas. Porque nos encanta que el séptimo arte nos invada en todas sus dimensiones y podamos incluir voces acerca de él y sus aledaños.Porque queremos escribir. Porque queremos leer. Porque nos interesa.</p>
        <p class="text-body-2 black--text text-center">Por todo esto... ¡BSO escribe!</p>
      </v-card-text>
    </v-card>
    <div v-if="$root.$isAdmin" style="width:100%;text-align:center;margin:10px;">
      <v-btn ripple color="primary" dark :href="'#/texto-editor/new'">
        <v-icon color="white">mdi-plus</v-icon>Agregar Texto
      </v-btn>
    </div>
    <div v-if="textos.length==0">
      <cargando-elem class="mb-2 ma-1" :lineas="6" fecha="true" v-for="skel in 10" :key="'skl-' + skel" />
    </div>
    <v-card v-for="(tex,i) in textos" :key="i" class="mb-2 ma-1 tag-parent">
      <div class="d-flex flex-no-wrap justify-space-between pr-3" >
        <div v-if="$root.$isAdmin && tex.publico==0" class="tagged borrador-tag">BORRADOR</div>
        <div v-if="$root.$isAdmin && tex.publico==1" class="tagged publicado-tag">PUBLICADO</div>
        <v-container>
          <v-layout row wrap>
            <v-flex xs12 sm4>
              <v-img :src="getImageUrl(tex)" :id="'img_' + tex.id" min-height="250" >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="red lighten-2"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-flex>
            <v-flex xs12 sm8 class="pl-2">
              <div>
                <div class="headline black--text">{{getTitulo(tex)}}</div>
                <div class="text--lighten-2 black--text" >{{tex.subtitulo}}</div>
                <div class="escrito-resenia-texto ma-1 text-justify" v-html="tex.resenia"></div>
                <div class="escrito-resenia-autor ma-1">Por {{tex.autor}}</div>
              </div>
            <v-card-actions class="pl-2">
              <v-btn class="v-btn-grad" color="primary" dark :href="'#/texto/' + tex.id">Leer Más</v-btn>
              <v-btn v-if="$root.$isAdmin" fab small color="blue" dark :href="'#/texto-editor/' + tex.id">
                <v-icon color="white">mdi-pencil</v-icon>
              </v-btn>
              <v-btn v-if="$root.$isAdmin" fab small color="red" dark>
                <v-icon color="white">mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </v-card>
    <div class="text-center" v-if="textos.length>0">
      <v-pagination v-model="pagina" :length="totalPaginas"></v-pagination>
    </div>
    <v-snackbar v-model="snackbar" color="error" top>{{ textoError }}
      <v-btn dark text @click="snackbar = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>

import CargandoElem from '../components/CargandoElem'

export default {
  name: "bso-escribe",
  components: {
    CargandoElem
  },
  data() {
    return {
      textos: [],
      pagina: 0,
      totalPaginas: 0,
      snackbar:false,
      textoError: null
    };
  },
  created() {
		let pag=this.$route.params.pagina;
		if (pag!=null){
			this.pagina=parseInt(pag);
		}
		else{
			this.pagina=1;
		}
	},
  methods: {
    getImageUrl: function(texto){
			return this.$root.$basepath + 'api/thumbnail.php?ty=tr&i=' + encodeURIComponent(texto.imagen) + this.$root.$webp;
		},
		getTitulo(texto){
			if (texto==null){return}
			let titulo=texto.titulo;
			if (texto.seccion!=null)
			{
				titulo=texto.seccion + ": " + titulo;
			}
			return titulo;
		},
		getPagina(){
			window.history.pushState(null,'', '#/bso-escribe/' + this.pagina);
			this.$http.get(this.$root.$basepath + "api/textos.php?p=" + this.pagina + "&tp=" + this.totalPaginas).then(result => {
            result.json().then(res=>{
                this.textos = res.textos;
                if (res.paginas!=null){
                  this.totalPaginas = res.paginas; 
                }
              });
            }, error => {
                this.mostrarError(error);
            });
		},
    mostrarError(texto){
      this.textoError=texto;
      this.snackbar=true;
    }
  },
  watch:{
		pagina: function(){
      this.textos = new Array();
      let _this = this;
      this.$nextTick().then(function () {
        _this.$vuetify.goTo(0, {duration: 500, easing: 'linear'});
        _this.getPagina();
      });
		}
	}
};
</script>
<style>
.escrito-resenia-autor {
	font-weight: bold;
}

.escrito-resenia-texto{
	text-align: justify;
}
.tagged{
	position: absolute;
    color: white;
    font-size: 12pt;
    bottom: 28px;
    text-align: center;
    right: -35px;
    padding: 5px;
    transform: rotate(-45deg);
    width: 160px;
    overflow: hidden;
}

.borrador-tag{
    background: red;
}
.publicado-tag{
    background: green;
}
.tag-parent{
	overflow: hidden;
}
</style>