<template>
  <div class="quienes-somos">
    <v-card class="mb-2">
      <v-card-text>
        <h1 class="text--lighten-1 black--text text-center pb-1">¿QUIENES SOMOS?</h1>
        <div class="text-body-2 pa-1 black--text">BSO (banda sonora original) es un programa de radio destinado a aquellos que disfrutan de dos elementos fundamentales del arte: el cine y la música. En ese sentido, creemos que las bandas sonoras son parte esencial de la historia del cine, ya que miles de artistas de todo el mundo sumaron su música a una enorme cantidad de películas que hoy siguen siendo recordadas por melodías, ritmos o canciones completas. En definitiva, sentimos que no existen ambas corrientes por separado sino que se encuentran fusionadas por la voluntad del tiempo y las experiencias personales que se llevan los espectadores luego de cada función en la sala de cine. Esa unión, rica y profunda, se encuentra repleta de historias, anécdotas y vivencias que en cada capítulo semanal de nuestro programa intentamos hacer llegar a los oyentes, siempre esperando que los anime a internarse en esos mundos fascinantes atiborrados de escenas, armonías y sensaciones. </div>
      </v-card-text>
    </v-card>
    <v-card class="mb-2">
      <v-card-text class="text-center body-1 black--text">
        <h2 class="text--lighten-1 black--text">STAFF</h2>
        <div class="staff-header">CONDUCCIÓN</div>
        <div>Diego Cirulo</div>
        <div>Fabio Villalba</div>
        <div class="staff-header">OPERADOR</div>
        <div>Juan Sixto</div>
        <div class="staff-header">LOCUCIÓN</div>
        <div>Juan Sixto (2012-2016)</div>
        <div>Camila Segovia (2016)</div>
        <div>Daniela Jorquera (2016 - presente)</div>
        <div class="staff-header">COLUMNAS, TEXTOS Y ENTREVISTAS</div>
        <div>Andrés Brandariz</div>
        <div>Diego Cirulo</div>
        <div>Luciana Eyras</div>
        <div>Sebastián Gentile</div>
        <div>Laura Marajofsky</div>
        <div>Mariano Morita</div>
        <div>Eugenia Rimoldi</div>
        <div>Belén Saitúa</div>
        <div>Fabio Villalba</div>
        <div class="staff-header">ILUSTRACIONES</div>
        <div>Nicolas Aponte A. Gutter</div>
        <div class="staff-header">PRODUCCIÓN GENERAL</div>
        <div>Diego Cirulo</div>
        <div>Fabio Villalba</div>
      </v-card-text>
    </v-card>
    <v-card class="mb-2">
      <v-card-text class="text-center body-1 black--text">
        <h2 class="text--lighten-1 black--text pb-1">Aquellos que aportan o aportaron en BSO</h2>
        <div>Nicolás Aponte A. Gutter</div>
        <div>Diego Avalos</div>
        <div>Martín Bargas</div>
        <div>Anabella Bustos</div>
        <div>Leandro Corengia</div>
        <div>Pablo De Vita</div>
        <div>Luciana Eyras</div>
        <div>Ángel Faretta</div>
        <div>Mariano Morita</div>
        <div>Francisco Noriega</div>
        <div>Guido Rosano</div>
        <div>Salvador Savarese</div>
        <div>Adrián Szmukler</div>
        <div>Marcos Vieytes</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "quienes-somos",
};
</script>
<style>

.staff-header {
	padding-top: 10px;
	width: 100%;
	font-weight: bold;
}

</style>