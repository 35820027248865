<template>
  <div class="bso-escribe">
    <v-card class="mb-2">
      <v-card-text>
        <h1 class="black--text text-center pb-1">BSO ESCUCHA - Entrevistas</h1>
        <p class="text-body-2 black--text">Escuchar es también parte importante del asunto. Nosotros nos la pasamos hablando de cine en nuestro programa y, a veces, sentimos que es necesario hacer un poco de silencio y recibir contenidos de los demás. Es por eso que esta sección se llama "BSO escucha", porque nos acercamos a realizadores, músicos, escritores, críticos, técnicos, etcétera, intentando aprender de su experiencia en el medio.</p>
        <p class="text-body-2 black--text text-center">Esperamos compartir con ustedes todas nuestras inquietudes y la sapiencia de nuestros entrevistados.</p>
      </v-card-text>
    </v-card>
    <div v-if="$root.$isAdmin" style="width:100%;text-align:center;margin:10px;">
      <v-btn ripple color="primary" dark :href="'#/entrevista-editor/new'">
        <v-icon color="white">mdi-plus</v-icon>Agregar Entrevista
      </v-btn>
    </div>
    <div v-if="entrevistas.length==0">
      <cargando-elem class="mb-2 ma-1" :lineas="6" fecha="true" v-for="skel in 10" :key="'skl-' + skel" />
    </div>
    <v-card v-for="(entrev,i) in entrevistas" :key="i" class="mb-2 ma-1 tag-parent">
      <div class="d-flex flex-no-wrap justify-space-between pr-3" >
        <div v-if="$root.$isAdmin && entrev.publico==0" class="tagged borrador-tag">BORRADOR</div>
        <div v-if="$root.$isAdmin && entrev.publico==1" class="tagged publicado-tag">PUBLICADO</div>
        <v-container>
          <v-layout row wrap>
            <v-flex xs12 sm4>
              <v-img :src="getImageUrl(entrev)" :id="'img_' + entrev.id" min-height="250" max-height="350">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="red lighten-2"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-flex>
            <v-flex xs12 sm8 class="pl-2">
              <div>
                <div class="headline text--lighten-1 black--text">{{entrev.titulo}}</div>
                <div class="entrevista-texto text-justify" v-html="entrev.texto"></div>
                <div class="entrevista-autor">Por {{entrev.autor}}</div>
              </div>
            <v-card-actions class="pl-2">
              <iframe v-if="entrev.ivoox!=null && entrev.mostrando==true" width="238" height="48" frameborder="0" allowfullscreen="" scrolling="no" :src="'https://ar.ivoox.com/es/player_ek_' + entrev.ivoox + '_2_1.html'"></iframe>
              <v-btn class="v-btn-grad" v-if="entrev.ivoox!=null && entrev.mostrando==false" color="red" @click="entrev.mostrando=true" dark>
                <img :src="require('../assets/logo_ivoox_sml.png')" style="padding-right:5px" />Escuchar</v-btn>
              <v-btn class="v-btn-grad" v-if="entrev.ivoox==null" :href="entrev.link" target="blank" color="red" dark>
                <img :src="require('../assets/logo_ivoox_sml.png')" style="padding-right:5px" />Escuchar</v-btn>
              <v-btn style="background-color:#1dd15d!important" v-if="entrev.spotify!=null" :href="entrev.spotify" target="blank" dark>
                <img :src="require('../assets/logo_spotify_sml.png')" style="padding-right:5px" />Escuchar
              </v-btn>
              <v-btn v-if="$root.$isAdmin" fab small color="blue" dark :href="'#/entrevista-editor/' + entrev.id">
                <v-icon color="white">mdi-pencil</v-icon>
              </v-btn>
              <v-btn v-if="$root.$isAdmin" fab small color="red" dark>
                <v-icon color="white">mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </v-card>
    <div class="text-center" v-if="entrevistas.length>0">
      <v-pagination v-model="pagina" :length="totalPaginas"></v-pagination>
    </div>
    <v-snackbar v-model="snackbar" color="error" top>{{ textoError }}
      <v-btn dark text @click="snackbar = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>

import CargandoElem from '../components/CargandoElem'

export default {
  name: "bso-escucha",
  components: {
    CargandoElem
  },
  data() {
    return {
      entrevistas: [],
      pagina: 0,
      totalPaginas: 0,
      snackbar:false,
      textoError: null
    };
  },
  created() {
		let pag=this.$route.params.pagina;
		if (pag!=null){
			this.pagina=parseInt(pag);
		}
		else{
			this.pagina=1;
		}
	},
  methods: {
    getImageUrl: function(entrevista){
			return this.$root.$basepath + 'api/thumbnail.php?ty=en&i=' + encodeURIComponent(entrevista.imagen) + this.$root.$webp;
		},
		getPagina(){
			window.history.pushState(null,'', '#/bso-escucha/' + this.pagina);
      this.$http.get(this.$root.$basepath + "api/entrevistas.php?p=" + this.pagina + "&tp=" + this.totalPaginas).then(result => {
        result.json().then(res =>{
            for(let i=0;i<res.entrevistas.length;i++){
                res.entrevistas[i].mostrando=false;
            }
            this.entrevistas = res.entrevistas;
            if (res.paginas!=null){
              this.totalPaginas = res.paginas; 
            }
          });
        }, error => {
          this.mostrarError(error);
      });
		},
    mostrarError(texto){
      this.textoError=texto;
      this.snackbar=true;
    }
  },
  watch:{
		pagina: function(){
			this.entrevistas = new Array();
      let _this = this;
      this.$nextTick().then(function () {
        _this.$vuetify.goTo(0, {duration: 500, easing: 'linear'});
        _this.getPagina();
      });
		}
	}
};
</script>
<style>
.entrevista-texto {
	text-overflow: ellipsis;
	overflow: hidden;
}

.entrevista-autor {
	font-weight: bold;
}

.tagged{
	position: absolute;
    color: white;
    font-size: 12pt;
    bottom: 28px;
    text-align: center;
    right: -35px;
    padding: 5px;
    transform: rotate(-45deg);
    width: 160px;
    overflow: hidden;
}

.borrador-tag{
    background: red;
}
.publicado-tag{
    background: green;
}
.tag-parent{
	overflow: hidden;
}
</style>