<template>
  <div class="img-overlay" v-if="zoom">
    <div class="img-holder"> 
      <img :src="getImagenZoom()" class="img-img" />
      <a nohref="nohref" class="img-close">
        <v-icon @click="cerrarZoom()">mdi-close</v-icon> 
      </a> 
      <a nohref="nohref" class="img-prev">
        <v-icon @click="anteriorImagen()">mdi-chevron-left</v-icon> 
      </a> 
      <a nohref="nohref" class="img-next">
        <v-icon @click="siguienteImagen()">mdi-chevron-right</v-icon> 
      </a> 
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return { imagenZoomIdx:null,zoom:false,/*basepath: "http://localhost/bso-radio/"*/}
  },
  props: ['cuadernos','show','idx'],
  methods:{
		cerrarZoom:function (index){
			this.$root.$reloadScrollBars();
			this.zoom=false;
			this.imagenZoomIdx=null;
			this.$root.$emit('zoom:off');
		},
		hacerZoom:function (index){
			this.imagenZoomIdx=this.idx;
			this.zoom=this.show;
			this.$root.$unloadScrollBars();
		},
		getImagenZoom(){
			if (this.imagenZoomIdx==null){return;}
			let img = this.$root.$basepath + 'imagenes/' + this.cuadernos[this.imagenZoomIdx].imagen;
				
			/*if (Modernizr.webp==true){
				img = img.replace('.jpg','.webp');
			}*/
			return img;
		},
		siguienteImagen(){
			this.imagenZoomIdx++;
			if (this.imagenZoomIdx==this.cuadernos.length){
				this.imagenZoomIdx=0;
			}
		},
		anteriorImagen(){
			this.imagenZoomIdx--;
			if (this.imagenZoomIdx==-1){
				this.imagenZoomIdx=(this.cuadernos.length-1);
			}
		},
	},
	watch: {
		show: function (val) {
			if (val==true){
				this.hacerZoom();
			}
		}
	}
};
</script>
<style>
.img-overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, .9);
	text-align: center;
	z-index: 10;
	box-sizing: border-box;
	display: flex;
	align-items: center;
}

.img-holder {
	/* max-width: 600px; */
	margin: 0 auto;
	position: relative;
	max-height: 100vh;
}

.img-close {
	right: 10px;
	top: 0;
	font-size: 30px;
	width: 50px;
	/* opacity: .6; */
	z-index: 1000000;
	position: absolute;
	text-align: center;
	box-sizing: border-box;
	color: rgba(255, 255, 255, .7);
	background-color: rgba(0, 0, 0, .5);
}

/*.img-close:hover {
	color: white;
	background-color: rgba(0, 0, 0, .7);
}*/

.img-next {
	right: 0;
	justify-content: flex-end;
}

.img-prev {
	left: 0;
	justify-content: flex-start;
}

.img-prev, .img-next {
	position: absolute;
	top: 0;
	bottom: 0;
	padding: 10px;
	width: 50%;
	box-sizing: border-box;
	font-size: 50px;
	display: flex;
	align-items: center;
}

.img-prev>i, .img-next>i, .img-close>i{
  width: 50px;
  height: 50px;
	font-size: 40px!important;
	position: relative;
	background-color: black;
	color: rgba(255, 255, 255, .7)!important;
}

.img-prev>i:hover, .img-next>i:hover, .img-close>i:hover {
	color: white!important;
}

.img-img {
	/* width: 100%;
    max-width: 600px; */
	cursor: pointer;
	box-sizing: border-box;
	display: block;
	max-height: 100vh;
	max-width: 100vw;
}
</style>