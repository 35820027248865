<template>
  <div class="texto">
    <v-layout v-if="texto!=null">
      <v-flex xs12>
        <v-card >
          <v-img v-if="texto.imagen!=null" :src="$root.$basepath + 'imagenes/' + texto.imagen" :height="getImgHeight()" class="grey lighten-2">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="red lighten-2"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-card-text>
            <v-layout row> 
              <v-flex xs12 sm10 lg8 offset-sm1 offset-lg2>
                <div>
                  <h3 class="headline mb-0 black--text text--darken-4">{{texto.titulo}}</h3>
                  <h3 class="title mb-0 black--text text--darken-1" style="font-size:18px!important">{{texto.subtitulo}}</h3>
                  <h3 class="subheading mb-0 autor-texto black--text">Por {{texto.autor}}</h3>
                  <div class="contenido-texto body-1 text-justify" v-html="texto.texto"></div>
                  <div style="width:100%;text-align:center">
                    <v-btn color="black" dark @click="volver()">Volver</v-btn>
                  </div>
                </div>
              </v-flex>
            </v-layout> 
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-bottom-sheet v-model="sheet" inset>
      <v-sheet class="text-justify px-8 py-4">
        <h5 v-html="nota">
        </h5>
      </v-sheet>
    </v-bottom-sheet>
  </div>
  
</template>

<script>

export default {
  name: "texto",
  data () {
    return { 
      texto:null,
      snackbar:false,
      textoError: null,
      sheet:false,
      nota: null
    }
	},
  watch:{
    texto(val){
      if(val){
        let me = this;
        this.$nextTick(()=>{
          let contenido = document.querySelector(".contenido-texto");
          contenido.querySelectorAll("sup").forEach((elem) => {
              const re = /\[\d+\]/;
              if (elem.innerHTML.match(re)){
                let id = "nota_link_" + elem.innerHTML.replace("[","").replace("]","");
                elem.className='link-nota';
                elem.id = id;
                elem.onclick=me.mostrarNota;
              }
          });
          contenido.querySelectorAll("h5").forEach((elem) => {
            const re = /\[\d+\].*/;
            if (elem.innerHTML.trim().match(re)){
              let num = elem.innerHTML.trim().substr(0,elem.innerHTML.trim().indexOf("]"));
              let id = "nota_" + num.replace("[","").replace("]","");
              elem.id = id;
              me.texto.notas[id]=elem.innerHTML.trim();
            }
          });
        })
      }
    }
  },
  created(){
    window.scrollToElement =this.scrollToElement;
  },
	mounted() {
    this.$vuetify.goTo(0, {duration: 500,
          easing: 'linear'});
    const idTexto=this.$route.params.id;
    let me = this;
    this.$http.get(this.$root.$basepath + "api/textos.php?t=" + idTexto).then(result => {
      result.json().then(texto =>{
        texto.notas=[];
        this.texto = texto;
      });
    }, error => {
      this.mostrarError(error);
    });
	},
	methods:{
		getImgHeight:function(){
			if (this.$vuetify.breakpoint.xs){
				return "200px";
			}
			return "400px";
		},
		volver: function(){
			this.texto=null;
			this.$router.go(-1);
		},
    mostrarError(texto){
      this.textoError=texto;
      this.snackbar=true;
    },
    mostrarNota: function(event){
      let idx = event.srcElement.id.replace("nota_link_","nota_");
      this.nota=this.texto.notas[idx];
      this.sheet=true;
    },
	}
};

</script>
<style>
.autor-texto {
	margin-bottom: 20px;
	font-weight: bold;
}
.contenido-texto p{
	margin-bottom:4px !important;
}
.link-nota {
  cursor:pointer;
  color: blue;
  text-decoration: underline;
}

.ql-indent-1:not(.ql-direction-rtl){
    padding-left:3em
}
.ql-indent-2:not(.ql-direction-rtl){
    padding-left:6em
}
.ql-indent-3:not(.ql-direction-rtl){
    padding-left:9em
}
.ql-indent-4:not(.ql-direction-rtl){
    padding-left:12em
}
.ql-indent-5:not(.ql-direction-rtl){
    padding-left:15em
}
.ql-indent-6:not(.ql-direction-rtl){
    padding-left:18em
}
.ql-indent-7:not(.ql-direction-rtl){
    padding-left:21em
}
.ql-indent-8:not(.ql-direction-rtl){
    padding-left:24em
}
.ql-indent-9:not(.ql-direction-rtl){
    padding-left:27em
}
.ql-bg-black{
    background-color:#000
}
.ql-bg-red{
    background-color:#e60000
}
.ql-bg-orange{
    background-color:#f90
}
.ql-bg-yellow{
    background-color:#ff0
}
.ql-bg-green{
    background-color:#008a00
}
.ql-bg-blue{
    background-color:#06c
}
.ql-bg-purple{
    background-color:#93f
}
.ql-color-white{
    color:#fff
}
.ql-color-red{
    color:#e60000
}
.ql-color-orange{
    color:#f90
}
.ql-color-yellow{
    color:#ff0
}
.ql-color-green{
    color:#008a00
}
.ql-color-blue{
    color:#06c
}
.ql-color-purple{
    color:#93f
}
.ql-font-serif{
    font-family:Georgia,Times New Roman,serif
}
.ql-font-monospace{
    font-family:Monaco,Courier New,monospace
}
.ql-size-small{
    font-size:.75em
}
.ql-size-large{
    font-size:1.5em
}
.ql-size-huge{
    font-size:2.5em
}
.ql-align-center{
    text-align:center
}
.ql-align-justify{
    text-align:justify
}
.ql-align-right{
    text-align:right
}
</style>