<template>
  <div class="bso-escribe">
    <v-card class="mb-2">
      <v-card-text>
        <h1 class="black--text text-center pb-1">ILUSTRES</h1>
        <p class="text-body-2 black--text text-center">"Ilustres" es la primera miniserie de BSO (banda sonora original) dedicada íntegramente a los artistas de la imagen fija en Argentina. Ilustradoras, dibujantes, pintores, historietistas, diseñadores, etcétera, son convocados para charlar sobre su profesión, el arte en general y, claro, la vida. </p>
      </v-card-text>
    </v-card>
    <div v-if="$root.$isAdmin" style="width:100%;text-align:center;margin:10px;">
      <v-btn ripple color="primary" dark :href="'#/ilustre-editor/new'">
        <v-icon color="white">mdi-plus</v-icon>Agregar Ilustre
      </v-btn>
    </div>
    <div v-if="ilustres.length==0">
      <cargando-elem class="mb-2 ma-1" :lineas="6" fecha="true" v-for="skel in 10" :key="'skl-' + skel" />
    </div>
    <v-card v-for="(ilustre,i) in ilustres" :key="i" class="mb-2 ma-1 tag-parent">
      <div class="d-flex flex-no-wrap justify-space-between pr-3" >
        <div v-if="$root.$isAdmin && ilustre.publico==0" class="tagged borrador-tag">BORRADOR</div>
        <div v-if="$root.$isAdmin && ilustre.publico==1" class="tagged publicado-tag">PUBLICADO</div>
        <v-container>
          <v-layout row wrap>
            <v-flex xs12 sm4>
              <v-img :src="getImageUrl(ilustre)" :id="'img_' + ilustre.id" min-height="250" max-height="350">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="red lighten-2"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-flex>
            <v-flex xs12 sm8 class="pl-2">
              <div>
                <div class="headline text--lighten-1 black--text">{{ilustre.titulo}}</div>
                <div class="ilustre-texto text-justify" v-html="ilustre.texto"></div>
              </div>
            <v-card-actions class="pl-2">
              <v-btn class="v-btn" v-if="ilustre.link!=null" color="red" dark :href="'#/ilustre/' + ilustre.id">
                <v-icon color="white">mdi-youtube</v-icon>Ver
              </v-btn>
              <v-btn class="v-btn-grad" v-if="ilustre.ivoox!=null" color="red" dark :href="ilustre.ivoox" target="_blank">
                <img :src="require('../assets/logo_ivoox_sml.png')" style="padding-right:5px" />Escuchar</v-btn>
              <v-btn v-if="$root.$isAdmin" fab small color="blue" dark :href="'#/ilustre-editor/' + ilustre.id">
                <v-icon color="white">mdi-pencil</v-icon>
              </v-btn>
              <v-btn v-if="$root.$isAdmin" fab small color="red" dark>
                <v-icon color="white">mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </v-card>
    <div class="text-center" v-if="ilustres.length>0">
      <v-pagination v-model="pagina" :length="totalPaginas"></v-pagination>
    </div>
    <v-snackbar v-model="snackbar" color="error" top>{{ textoError }}
      <v-btn dark text @click="snackbar = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>

import CargandoElem from '../components/CargandoElem'

export default {
  name: "ilustres",
  components: {
    CargandoElem
  },
  data() {
    return {
      ilustres: [],
      pagina: 0,
      totalPaginas: 0,
      snackbar:false,
      textoError: null
    };
  },
  created() {
		let pag=this.$route.params.pagina;
		if (pag!=null){
			this.pagina=parseInt(pag);
		}
		else{
			this.pagina=1;
		}
	},
  methods: {
    getImageUrl: function(ilustre){
			return this.$root.$basepath + 'api/thumbnail.php?ty=il&i=' + encodeURIComponent(ilustre.imagen) + this.$root.$webp;
		},
		getPagina(){
			window.history.pushState(null,'', '#/ilustres/' + this.pagina);
      this.$http.get(this.$root.$basepath + "api/ilustres.php?p=" + this.pagina + "&tp=" + this.totalPaginas).then(result => {
        result.json().then(res =>{
            for(let i=0;i<res.ilustres.length;i++){
                res.ilustres[i].mostrando=false;
            }
            this.ilustres = res.ilustres;
            if (res.paginas!=null){
              this.totalPaginas = res.paginas; 
            }
          });
        }, error => {
          this.mostrarError(error);
      });
		},
    mostrarError(texto){
      this.textoError=texto;
      this.snackbar=true;
    }
  },
  watch:{
		pagina: function(){
			this.ilustres = new Array();
      let _this = this;
      this.$nextTick().then(function () {
        _this.$vuetify.goTo(0, {duration: 500, easing: 'linear'});
        _this.getPagina();
      });
		}
	}
};
</script>
<style>
.ilustre-texto {
	text-overflow: ellipsis;
	overflow: hidden;
}


.tagged{
	position: absolute;
    color: white;
    font-size: 12pt;
    bottom: 28px;
    text-align: center;
    right: -35px;
    padding: 5px;
    transform: rotate(-45deg);
    width: 160px;
    overflow: hidden;
}

.borrador-tag{
    background: red;
}
.publicado-tag{
    background: green;
}
.tag-parent{
	overflow: hidden;
}
</style>